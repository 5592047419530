import { generateHexUuid } from "~/utilities/generate-hex-uuid";

const SESSION_STORAGE = "sessionId";
const LAST_ACTIVITY_STORAGE = "lastActivity";
const SESSION_TIMEOUT = 30 * 60 * 1000; // 30 minutes

/**
 * Checks if the session is active based on the last activity timestamp stored in sessionStorage.
 * @returns {boolean} - True if the session is active, false otherwise.
 * @example isSessionActive() => true
 */
export const isSessionActive = (): boolean => {
	const lastActivity = parseInt(sessionStorage.getItem(LAST_ACTIVITY_STORAGE) || "0", 10);
	return Date.now() - lastActivity <= SESSION_TIMEOUT;
};

/**
 * Retrieves the session ID from sessionStorage. If the session is active, the last activity timestamp is updated.
 * If the session is not active, a new session ID is generated and stored.
 * @returns {string} - The session ID.
 * @example getSessionId() => "550e8400e29b41d4a716446655440000"
 */
export const getSessionId = (): string => {
	const now = Date.now();

	if (!isSessionActive()) {
		const newSessionId = generateHexUuid();
		sessionStorage.setItem(SESSION_STORAGE, newSessionId);
		sessionStorage.setItem(LAST_ACTIVITY_STORAGE, now.toString());
		return newSessionId;
	}

	sessionStorage.setItem(LAST_ACTIVITY_STORAGE, now.toString());
	let sessionId = sessionStorage.getItem(SESSION_STORAGE);
	if (!sessionId) {
		sessionId = generateHexUuid();
		sessionStorage.setItem(SESSION_STORAGE, sessionId);
	}
	return sessionId;
};

// Add event listeners to update the session ID on user activity
["click", "keypress"].forEach((event) => {
	window.addEventListener(event, () => {
		getSessionId();
	});
});
